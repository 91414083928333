import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Icon from "@mui/material/Icon";
import TableComponent from "layouts/eventpost";
import Users from "layouts/user/user";
import Role from "layouts/role/role";
import Compagnies from "layouts/compagnies/compagnies";
import SignOut from "layouts/authentication/sign-up";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    role: ["SUPER ADMIN", "ADMIN", "AUTHOR"],
  },
  {
    type: "collapse",
    name: "Manage post",
    key: "event-post",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/event-post",
    component: <TableComponent />,
    role: ["SUPER ADMIN", "ADMIN"],
  },
  {
    type: "collapse",
    name: "Compagnies",
    key: "compagnies",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/compagnies",
    component: <Compagnies />,
    role: ["SUPER ADMIN"],
  },
  {
    type: "collapse",
    name: "Users role",
    key: "role",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/role",
    component: <Role />,
    role: ["SUPER ADMIN"],
  },
  {
    type: "collapse",
    name: "Users Manage",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
    role: ["SUPER ADMIN", "ADMIN"],
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    role: ["SUPER ADMIN", "ADMIN", "AUTHOR"],
  },
  {
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign-Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/sign-out",
    component: <SignOut />,
    role: ["SUPER ADMIN", "ADMIN", "AUTHOR"],
  },
];

export default routes;
