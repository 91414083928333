import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  Box,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getUsers, createUser, updateUser, deleteUser, getUserWithCompany } from "./userService";
import { getCompany } from "../compagnies/companyService";
import { getAllRoles } from "../role/roleService";
import { IconButton } from "@mui/material";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.css"; // Importation de ton fichier CSS

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));
const FileInput = styled("input")({
  display: "none",
});
const getToken = () => {
  return localStorage.getItem("token");
};
const Users = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    id: null,
    name: "",
    email: "",
    role_id: "",
    compagny_id: "",
    biography: "",
    phone: "",
    photo: "",
    password: "",
    password_confirmation: "",
    compagny_id: [],
  });
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [compagnies, setCompagnies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const fileInputRef = useRef(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Notification State
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [phone, setPhone] = useState("");

  const showAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenSnackbar(true);
  };
  const fetchUserCompanies = async () => {
    const userId = localStorage.getItem("currentUserId");
    if (!userId) {
      console.error("L'utilisateur n'est pas connecté.");
      return [];
    }

    try {
      const response = await getUserWithCompany(userId); // Récupère l'utilisateur et ses compagnies
      const companies = Array.isArray(response?.compagnies) ? response.compagnies : []; // Vérifie que 'compagnies' est un tableau
      console.log("Compagnies récupérées pour l'utilisateur :", companies);
      setUserCompanies(companies); // Met à jour l'état avec les compagnies récupérées
      return companies.map((company) => company.id); // Retourne uniquement les IDs des compagnies
    } catch (error) {
      console.error("Erreur lors de la récupération des compagnies de l'utilisateur :", error);
      return [];
    }
  };

  const fetchUsers = async (companyIds, isAdmin) => {
    try {
      const users = await getUsers();
      const usersArray = Array.isArray(users) ? users : [];
      console.log("Users fetched:", usersArray);

      // Filtre les utilisateurs en fonction du rôle si l'utilisateur connecté est un ADMIN
      const filteredUsers = isAdmin
        ? usersArray.filter((user) => {
            const isSuperAdmin = user.role_name === "SUPER ADMIN" || user.role_id === 1;

            // Exclure les utilisateurs avec le rôle SUPER ADMIN
            if (isSuperAdmin) {
              console.log(`Exclusion de l'utilisateur SUPER ADMIN : ${user.name}`);
              return false; // Exclure cet utilisateur
            }

            // Si l'utilisateur n'a pas de compagnies ou si ses compagnies correspondent à celles de l'ADMIN
            if (Array.isArray(user.compagnies) && user.compagnies.length > 0) {
              console.log("Compagnies de l'utilisateur :", user.compagnies);

              // Vérifie si l'utilisateur appartient à l'une des compagnies de l'ADMIN
              const hasMatchingCompany = user.compagnies.some((comp) =>
                companyIds.includes(comp.id)
              );

              console.log(
                `L'utilisateur ${user.name} appartient-il à l'une des compagnies de l'ADMIN ? ${hasMatchingCompany}`
              );
              return hasMatchingCompany; // Inclure l'utilisateur si une compagnie correspond
            }

            console.warn(`L'utilisateur ${user.name} n'a pas de compagnies valides.`);
            return false; // Exclure si aucune compagnie valide
          })
        : usersArray; // Si ce n'est pas un ADMIN, affiche tous les utilisateurs sans filtrage

      console.log("Utilisateurs filtrés :", filteredUsers);
      setData(filteredUsers); // Met à jour l'état avec les utilisateurs filtrés
    } catch (error) {
      console.error("Échec de la récupération des utilisateurs :", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const companies = await fetchUserCompanies(); // Récupère les compagnies de l'utilisateur
      if (companies.length > 0) {
        const isAdmin = localStorage.getItem("userRole") === "ADMIN"; // Vérifie si l'utilisateur connecté est ADMIN
        await fetchUsers(companies, isAdmin); // Récupère les utilisateurs associés aux compagnies
      } else {
        console.warn("Aucune compagnie associée à l'utilisateur connecté.");
      }
    };

    const fetchCompagnies = async () => {
      try {
        const compagniesList = await getCompany(); // Récupère toutes les compagnies
        setCompagnies(Array.isArray(compagniesList) ? compagniesList : []);
      } catch (error) {
        console.error("Erreur lors de la récupération des compagnies :", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const rolesList = await getAllRoles();
        console.log("Liste des rôles récupérés :", rolesList);

        // Filtrer les rôles pour exclure "super admin" et "author"
        const filteredRoles = Array.isArray(rolesList)
          ? rolesList.filter(
              (role) =>
                role.name.toLowerCase() !== "super admin" && role.name.toLowerCase() !== "author"
            )
          : [];

        console.log("Rôles après filtrage :", filteredRoles);

        setRoles(filteredRoles);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles :", error);
      }
    };

    // Appels des fonctions définies
    fetchCompagnies(); // Récupère toutes les compagnies
    fetchRoles(); // Récupère les rôles
    fetchData(); // Appel pour récupérer les compagnies de l'utilisateur et les utilisateurs associés

    // Met en place un intervalle pour actualiser les données toutes les 5 secondes
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval); // Nettoyage de l'intervalle au démontage du composant
  }, []);

  const ImagePreview = styled("img")({
    maxWidth: "100px",
    maxHeight: "100px",
    objectFit: "cover",
    margin: "5px",
  });

  const handleOpen = (item) => {
    setCurrentItem(
      item
        ? {
            id: item.id,
            name: item.name || "",
            email: item.email || "",
            role_id: item.role?.id || "",
            compagny_id: item.compagnies?.map((c) => c.id) || [],
            biography: item.biography || "",
            phone: item.phone || "",
            password: item.password || "",
            password_confirmation: item.password_confirmation || "",
            photo: item.photo || "",
          }
        : {
            id: null,
            name: "",
            email: "",
            role_id: "",
            compagny_id: [],
            biography: "",
            phone: "",
            password: "",
            password_confirmation: "",
            photo: "",
          }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({
      id: null,
      name: "",
      email: "",
      role_id: "",
      compagny_id: [],
      biography: "",
      phone: "",
      photo: "",
      password: "",
      password_confirmation: "",
      compagny_id: "",
    });
    setOpen(false);
  };

  const handleSave = async () => {
    if (isSubmitting) return; // Empêche une soumission multiple
    setIsSubmitting(true); // Désactive le bouton
    try {
      // Vérifie les valeurs de mot de passe
      console.log("Password:", currentItem.password);
      console.log("Password Confirmation:", currentItem.password_confirmation);

      // Vérification de l'égalité des mots de passe
      if (currentItem.password.trim() !== currentItem.password_confirmation.trim()) {
        setError("Passwords do not match.");
        return;
      }

      const userData = {
        id: currentItem.id,
        name: currentItem.name,
        email: currentItem.email,
        role_id: currentItem.role_id,
        biography: currentItem.biography,
        phone: currentItem.phone,
        photo: currentItem.photo,
        password: currentItem.password,
        password_confirmation: currentItem.password_confirmation,
        compagny_id: currentItem.compagny_id,
      };

      // Validation des champs requis
      if (
        !currentItem.name ||
        !currentItem.email ||
        !currentItem.role_id ||
        !currentItem.compagny_id.length ||
        !currentItem.password
      ) {
        setError("Name, email, role, and password are required.");
        setIsSubmitting(false); // Réactive le bouton
        return;
      }

      if (currentItem.id) {
        await updateUser(userData);
        setData(data.map((user) => (user.id === currentItem.id ? currentItem : user)));
        showAlert("User updated successfully.");
      } else {
        const newUser = await createUser(userData); // Appel pour créer un nouvel utilisateur
        setData([...data, newUser]);
        showAlert("Post created successfully.");
      }
      handleClose();
    } catch (error) {
      console.error("Failed to save user:", error);
    } finally {
      setIsSubmitting(false); // Réactive le bouton
    }
  };

  const handleDelete = async (id) => {
    try {
      // Crée un objet userData avec l'ID
      const userData = { id: id };
      await deleteUser(userData); // Passe l'objet à deleteUser
      setData(data.filter((item) => item.id !== id));
      handleCloseConfirm();
      showAlert("Post deleted successfully.");
    } catch (error) {
      console.error("Failed to delete user", error);
    }
  };

  const handleOpenConfirm = (item) => {
    setItemToDelete(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setItemToDelete(null);
    setOpenConfirm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const validateImage = (image) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2 Mo

    if (!validTypes.includes(image.type)) {
      return { valid: false, message: "Type de fichier non valide" };
    }

    if (image.size > maxSize) {
      return { valid: false, message: "Le fichier est trop volumineux" };
    }

    return { valid: true };
  };

  const paginatedData = Array.isArray(data)
    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  const uploadImage = async (photo) => {
    const token = getToken();
    console.log("Token:", token);

    const validation = validateImage(photo);
    console.log("Image validation:", validation);

    if (!validation.valid) {
      console.error("Validation failed:", validation.message);
      throw new Error(validation.message);
    }

    const formData = new FormData();
    formData.append("photo", photo);
    console.log("FormData content:", formData.get("photo")); // Affiche l'image ajoutée au FormData

    const response = await fetch(`https://tpostapi.mytk.app/api/upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    console.log("Response status:", response.status);
    if (!response.ok) {
      console.error("Upload error:", await response.text());
      throw new Error("Erreur lors de l'upload");
    }

    const result = await response.json();
    console.log("Upload successful:", result);
    return result.image_url;
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);

    if (files.length === 0) {
      console.error("Veuillez sélectionner au moins un fichier.");
      return;
    }

    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    if (validFiles.length === 0) {
      console.error("Aucun fichier sélectionné n'est une image.");
      return;
    }

    const newImageUrls = validFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newImageUrls]);

    for (const image of validFiles) {
      try {
        const uploadedImageUrl = await uploadImage(image);
        console.log("Image uploadée avec succès:", uploadedImageUrl);

        // Enregistrement de l'URL de l'image dans le currentItem
        setCurrentItem((prev) => ({
          ...prev,
          photo: uploadedImageUrl,
        }));
      } catch (error) {
        console.error("Erreur lors de l'upload de l'image:", error);
      }
    }
  };

  const handleDeletePhoto = async () => {
    const token = getToken();

    try {
      const response = await fetch(`https://tpostapi.mytk.app/api/del_img`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          photo: currentItem.photo, // Envoyer l'URL de la photo à supprimer
        }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du logo.");
      }

      // Suppression réussie, mise à jour de l'état local
      setCurrentItem((prev) => ({
        ...prev,
        photo: "", // Vider le champ logo
      }));

      // Optionnel : Mettre à jour la liste principale si nécessaire
      setData((prevData) =>
        prevData.map((user) => (user.id === currentItem.id ? { ...user, logo: "" } : user))
      );
    } catch (error) {
      console.error("Erreur lors de la suppression du logo:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} sx={{ minHeight: "80vh" }}>
        {/* Snackbar for Notifications */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <MDBox>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CenteredCard>
                <CardContent>
                  <Button
                    onClick={() => handleOpen()}
                    sx={{
                      backgroundColor: "#87CEFA",
                      color: "#FFFFFF",
                      marginLeft: "12px",
                      marginTop: "-10px",
                      "&:hover": {},
                      "& .MuiButton-label": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Add User
                  </Button>
                  <TableContainer component={Paper} style={{ marginTop: 10 }}>
                    <Table>
                      <TableRow>
                        <TableCell style={{ width: "25%", fontWeight: "bold" }}>Name</TableCell>
                        <TableCell style={{ width: "25%", fontWeight: "bold" }}>Email</TableCell>
                        <TableCell style={{ width: "20%", fontWeight: "bold" }}>Role</TableCell>
                        <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                          Compagnies
                        </TableCell>
                        <StyledTableCell style={{ width: "20%", fontWeight: "bold" }}>
                          Actions
                        </StyledTableCell>
                      </TableRow>
                      <TableBody>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>{item.name ? item.name : "N/A"}</TableCell>

                              <TableCell>{item.email ? item.email : "N/A"}</TableCell>

                              <TableCell>{item.role?.name ? item.role.name : "No Role"}</TableCell>

                              <TableCell>
                                {item.compagnies?.[0]?.name
                                  ? item.compagnies[0].name
                                  : "No Company"}
                              </TableCell>

                              <StyledTableCell>
                                <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                                  <Button onClick={() => handleOpen(item)}>
                                    <Edit />
                                  </Button>
                                  <Button onClick={() => handleOpenConfirm(item)}>
                                    <Delete sx={{ color: "red" }} />
                                  </Button>
                                </Box>
                              </StyledTableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow></TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[7, 10, 25]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ padding: "16px" }}
                    />
                  </TableContainer>
                </CardContent>
              </CenteredCard>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentItem.id ? "Edit User" : "Add User"}</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={currentItem.name}
                    onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                  />
                  <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    value={currentItem.email}
                    onChange={(e) => setCurrentItem({ ...currentItem, email: e.target.value })}
                  />
                  <TextField
                    margin="dense"
                    name="password"
                    label="New Password (leave blank if no change)"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={currentItem.password}
                    onChange={(e) => setCurrentItem({ ...currentItem, password: e.target.value })}
                  />
                  <TextField
                    margin="dense"
                    name="password_confirmation"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={currentItem.password_confirmation}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, password_confirmation: e.target.value })
                    }
                  />

                  {/* Cacher le champ des rôles si l'utilisateur est un Super Admin */}
                  {currentItem.role_id !== 1 && (
                    <FormControl fullWidth margin="dense">
                      <InputLabel>Roles</InputLabel>
                      <Select
                        value={currentItem.role_id || ""}
                        onChange={(e) =>
                          setCurrentItem({ ...currentItem, role_id: e.target.value })
                        }
                        sx={{ height: "43px" }}
                      >
                        {roles.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {/* Cacher le champ des compagnies si l'utilisateur est un Super Admin */}
                  {currentItem.role_id !== 1 && (
                    <FormControl fullWidth margin="dense">
                      <InputLabel>Compagnies</InputLabel>
                      <Select
                        multiple
                        value={currentItem.compagny_id || []}
                        onChange={(e) =>
                          setCurrentItem({ ...currentItem, compagny_id: e.target.value })
                        }
                        sx={{ height: "43px" }}
                      >
                        {(localStorage.getItem("userRole") === "ADMIN"
                          ? userCompanies
                          : compagnies
                        ).map((compagny) => (
                          <MenuItem key={compagny.id} value={compagny.id}>
                            {compagny.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <TextField
                    margin="dense"
                    label="Biographie"
                    fullWidth
                    multiline
                    rows={4}
                    value={currentItem.biography}
                    onChange={(e) => setCurrentItem({ ...currentItem, biography: e.target.value })}
                  />
                  <PhoneInput
                    international
                    defaultCountry="BJ"
                    value={currentItem.phone}
                    onChange={(value) => {
                      console.log("Numéro de téléphone mis à jour:", value);
                      setCurrentItem({ ...currentItem, phone: value });
                    }}
                    placeholder="Enter phone number"
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                    inputStyle={{
                      height: "50px",
                      fontSize: "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    inputContainerStyle={{ display: "flex", width: "100%" }}
                    className="custom-phone-input"
                  />
                  <Box sx={{ marginTop: 2 }}>
                    <InputLabel></InputLabel>
                    <FileInput
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                      Upload Images
                    </Button>

                    <Box sx={{ position: "relative", marginTop: 2 }}>
                      {currentItem.photo ? (
                        <>
                          <ImagePreview
                            src={`https://tpostapi.mytk.app${currentItem.photo}`}
                            alt="User photo"
                            sx={{ width: 200, height: 200, objectFit: "cover" }} // Vous pouvez ajuster la taille de l'image ici
                          />

                          {/* Icone de suppression positionnée en haut à droite de l'image */}
                          <IconButton
                            onClick={handleDeletePhoto}
                            color="error"
                            sx={{
                              position: "absolute",
                              top: 8, // Positionner 8px à partir du haut
                              right: 8, // Positionner 8px à partir de la droite
                              zIndex: 1, // S'assurer que l'icône est au-dessus de l'image
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      ) : (
                        <p>No images uploaded yet.</p>
                      )}
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSave} color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={openConfirm} onClose={handleCloseConfirm}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete this user?</DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleDelete(itemToDelete.id)} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Footer />
      </Box>
    </DashboardLayout>
  );
};

export default Users;
