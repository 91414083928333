import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Projects from "layouts/dashboard/components/Projects";
import { getPosts } from "layouts/eventpost/indexService";
import { getUserWithCompany } from "layouts/user/userService";

function Dashboard() {
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    new: 0,
    inProgress: 0,
    submitted: 0,
  });
  const [userRole, setUserRole] = useState(""); // Rôle de l'utilisateur
  const [userCompanies, setUserCompanies] = useState([]); // Compagnies de l'utilisateur connecté
  const [statusFilter, setStatusFilter] = useState(""); // Filtre de statut, si nécessaire

  // Récupère les informations de l'utilisateur et ses compagnies
  const fetchUserCompanies = async () => {
    const userId = localStorage.getItem("currentUserId");
    if (!userId) {
      console.error("L'utilisateur n'est pas connecté.");
      return [];
    }

    try {
      const response = await getUserWithCompany(userId);
      const companies = Array.isArray(response?.compagnies) ? response.compagnies : [];
      setUserCompanies(companies);
      return companies;
    } catch (error) {
      console.error("Erreur lors de la récupération des compagnies de l'utilisateur :", error);
      return [];
    }
  };

  const fetchData = async () => {
    try {
      const companies = await fetchUserCompanies();
      let companyIds = [];
      if (companies.length > 0) {
        companyIds = companies.map((company) => company.id);
        console.log("IDs des compagnies de l'utilisateur :", companyIds);
      } else {
        console.warn("Aucune compagnie associée à l'utilisateur connecté.");
      }

      const posts = await getPosts(); // Récupére tous les posts
      console.log("Posts récupérés :", posts);

      if (!Array.isArray(posts)) {
        console.warn("La réponse des posts n'est pas un tableau.");
        return;
      }

      let filteredPosts = posts;

      // Appliquer le filtre en fonction du rôle
      if (userRole === "ADMIN") {
        filteredPosts = posts.filter((post) => companyIds.includes(post.compagny_id)); // Filtre par compagnies de l'utilisateur
      }

      // Applique le filtre de statut, si applicable
      if (statusFilter) {
        filteredPosts = filteredPosts.filter((post) => post.state === statusFilter);
      }

      // Calcule les statistiques
      const total = filteredPosts.length;
      const newCount = filteredPosts.filter((post) => post.state === "new").length;
      const inProgressCount = filteredPosts.filter((post) => post.state === "inProgress").length;
      const submittedCount = filteredPosts.filter((post) => post.state === "submitted").length;

      // Mettre à jour les statistiques
      setStats({ total, new: newCount, inProgress: inProgressCount, submitted: submittedCount });

      // Vérification des données avant de les mettre dans l'état
      const validatedPosts = filteredPosts.map((post) => {
        return createData(
          post.code || "",
          post.title || "",
          post.description || "",
          post.state || "",
          post.id,
          post.compagny_id,
          post.images || [],
          post.startDate || "",
          post.endDate || ""
        );
      });

      setData(validatedPosts); // Met à jour l'état avec les posts filtrés et formatés
    } catch (error) {
      console.error("Erreur lors de la récupération des posts :", error);
    }
  };

  useEffect(() => {
    const userRoleFromLocalStorage = localStorage.getItem("userRole"); // Récupère le rôle de l'utilisateur depuis le localStorage
    setUserRole(userRoleFromLocalStorage); // Met à jour l'état avec le rôle récupéré

    fetchData(); // Appel initial pour récupérer les compagnies et les posts
  }, [statusFilter, userRole]); // Re-exécute fetchData chaque fois que le filtre de statut ou le rôle change

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="article"
                title=""
                count={stats.total}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "All",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="article"
                title=""
                count={stats.new}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "New",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="article"
                title=""
                count={stats.inProgress}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "In progress",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="article"
                title=""
                count={stats.submitted}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Submit for approbation",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={20}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
