import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/material";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useMediaQuery, useTheme } from "@mui/material";

function SignOut() {
  const navigate = useNavigate();
  const location = useLocation(); // Permet de détecter la route actuelle
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Vérifie si l'écran est mobile

  const [openDialog, setOpenDialog] = useState(false); // L'état pour afficher ou masquer le Dialog

  const handleLogout = () => {
    // Appel à l'API de déconnexion
    fetch("https://tpostapi.mytk.app/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("La déconnexion a échoué");
      })
      .then((data) => {
        console.log(data.message);
        // Suppression du token et redirection
        localStorage.removeItem("token");
        navigate("/authentication/sign-in");
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion:", error);
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Fermer le Dialog
    navigate("/dashboard"); // Redirige vers le Dashboard lorsque l'utilisateur annule
  };

  const handleDialogOpen = () => {
    setOpenDialog(true); // Ouvrir le Dialog
  };

  // Utilise useEffect pour afficher le popup si la route /sign-out est visitée
  useEffect(() => {
    if (location.pathname === "/sign-out") {
      setOpenDialog(true); // Ouvre le popup si la route correspond
    }
  }, [location.pathname]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Le bouton Sign Out qui ouvre le Dialog */}
      <Button
        onClick={isMobile ? handleDialogOpen : handleLogout}
        variant="contained"
        color="primary"
      >
        <Icon fontSize="small">login</Icon>
        Sign Out
      </Button>

      {/* Dialog pour mobile uniquement */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Sign Out</DialogTitle>
        <DialogContent>Are you sure you want to log out? </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleLogout(); // Effectuer la déconnexion après confirmation
              handleDialogClose();
            }}
            color="primary"
            autoFocus
          >
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SignOut;
