import React, { useEffect, useState } from "react";
import { Button, TextField, Box, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import { getUsers, updateUser } from "layouts/user/userService";
import MDAvatar from "components/MDAvatar"; // Importation de l'avatar

// URL d'une image par défaut (au cas où il n'y a pas de photo)
import burceMars from "assets/images/bruce-mars.jpg";

function Overview() {
  const [user, setUser] = useState({
    name: "",
    biography: "",
    phone: "",
    email: "",
    compagnies: [],
    photo: "", // Ajouter une propriété photo pour stocker l'image de l'utilisateur
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUserId = localStorage.getItem("currentUserId");

      if (!currentUserId) {
        setError("Aucun utilisateur connecté.");
        setLoading(false);
        return;
      }

      try {
        const usersData = await getUsers(); // Supposons que cette fonction récupère les données des utilisateurs
        const currentUser = usersData.find((u) => u.id === Number(currentUserId));

        if (currentUser) {
          // Assurez-vous que la photo contient une URL complète
          const baseURL = "https://tpostapi.mytk.app"; // Remplacez par l'URL de base correcte
          const photoUrl = currentUser.photo ? `${baseURL}${currentUser.photo}` : burceMars;

          setUser({
            ...currentUser,
            photo: photoUrl, // Stocker l'URL complète de l'image
          });
          console.log("Utilisateur connecté :", currentUser);
        } else {
          setError("Utilisateur non trouvé.");
        }
      } catch (error) {
        setError("Erreur lors de la récupération des données de l'utilisateur");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      // Mise à jour des données de l'utilisateur
      await updateUser(user);
      setIsEditing(false);
    } catch (error) {
      setError("Erreur lors de la sauvegarde des données.");
      console.error(error);
    }
  };

  if (loading) {
    return <MDTypography variant="h6">Chargement...</MDTypography>;
  }

  if (error) {
    return (
      <MDTypography variant="h6" color="error">
        {error}
      </MDTypography>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={0} />
      <Header>
        <MDBox mt={5} mb={3} sx={{ minHeight: "35vh" }}>
          {isEditing ? (
            <form onSubmit={handleSave}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Biography"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={user.biography}
                    onChange={(e) => setUser({ ...user, biography: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    value={user.phone}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                </Grid>
              </Grid>
              <Box mt={3}>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
                <Button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  variant="outlined"
                  color="secondary"
                  sx={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          ) : (
            <>
              {/* Affichage de l'avatar de l'utilisateur avec l'image complète */}
              <MDAvatar
                src={user.photo} // Utilisation de l'URL complète de la photo
                alt={user.name || "Utilisateur"}
                sx={{ width: 80, height: 80 }} // Taille de l'avatar, ajustez comme nécessaire
              />
              <ProfileInfoCard
                title={user.name || "Nom indisponible"}
                description={user.biography || "Biographie non renseignée"}
                info={{
                  fullName: user.name || "Non renseigné",
                  mobile: user.phone || "Non renseigné",
                  email: user.email || "Non renseigné",
                  "organisation(s)":
                    Array.isArray(user.compagnies) && user.compagnies.length > 0
                      ? user.compagnies.map((c) => c.name).join(", ")
                      : "Non renseigné",
                }}
                action={{ tooltip: "Edit Profile", onClick: handleEditClick }}
                shadow={false}
              />
            </>
          )}
        </MDBox>
      </Header>
      <MDBox mb={8} />
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
