import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController } from "context";
import Basic from "layouts/authentication/sign-in";

export default function App() {
  const [controller] = useMaterialUIController();
  const { layout, darkMode } = controller;

  const [role, setRole] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedRole = localStorage.getItem("userRole");
    const token = localStorage.getItem("token");

    if (storedRole && token) {
      const trimmedRole = storedRole.trim();
      setRole(trimmedRole);
      setIsAuthenticated(true);
      console.log("Rôle récupéré :", trimmedRole);
    } else {
      console.log("Aucun rôle ou token trouvé dans localStorage");
      setIsAuthenticated(false);
    }
  }, []);

  const hasAccess = (allowedRoles) => {
    if (!role) {
      console.log("Aucun rôle défini");
      return false; // Aucun accès si le rôle n'est pas défini
    }
    console.log(`Rôle de l'utilisateur : ${role}, Rôles autorisés : ${allowedRoles}`);
    return allowedRoles && allowedRoles.includes(role);
  };

  // Cette fonction retourne uniquement les routes accessibles
  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      // Vérifie si le rôle de l'utilisateur a accès à la route
      if (route.role && !hasAccess(route.role)) {
        console.log(`Accès refusé à la route: ${route.route} (Rôles autorisés: ${route.role})`);
        return null; // Ne retourne rien si l'accès est refusé
      }
      console.log(`Accès accordé à la route: ${route.route}`);
      return <Route path={route.route} element={route.component} key={route.key} />;
    });
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav routes={routes.filter((route) => hasAccess(route.role))} />
      )}
      <Routes>
        <Route
          path="/authentication/sign-in"
          element={<Basic setRole={setRole} setIsAuthenticated={setIsAuthenticated} />}
        />
        {getRoutes(routes)}
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <Navigate
                to={role === "SUPER ADMIN" || role === "AUTHOR" ? "/dashboard" : "/event-post"}
              />
            ) : (
              <Navigate to="/authentication/sign-in" />
            )
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
